<template>
  <b-modal
    id="edit-payment-external-employee"
    ref="modal"
    header-class="headerModal"
    title="Add Employee Payment"
    hide-footer
    centered
    @close="onCancel"
  >
    <b-form>
      <b-form>
        <b-form-group
          id="input-group-9"
          :label="$t('Amount')"
          style="width:100%"
          label-for="input-25"
        >
          <b-form-input
            id="input-1"
            v-model="form.amount"
            type="number"
          />
        </b-form-group>
        <b-form-group
          id="input-group-7"
          :label="$t('Notes')"
          style="width:100%"
          label-for="input-25"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.notes"
            placeholder="Write here . . . "
            rows="3"
            max-rows="3"
          />
        </b-form-group>
      </b-form>
    </b-form>
    <div style="margin-top: 10px;">
      <b-button
        type="button"
        variant="none"
        class="buttonSubmit"
        @click="onSubmit"
      >
        {{ $t("Submit") }}
      </b-button>
      <b-button
        type="button"
        variant="danger"
        class="buttonCancel"
        style="margin-right: 15px"
        @click="onCancel"
      >
        {{ $t("Cancel") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['form', 'paid'],
  data() {
    return {

    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form);
      this.paid = false
      this.$emit('onSubmitPaid', this.paid);
      this.$refs.modal.hide()
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.paid = false
      this.$emit('isPaid', this.paid);
      this.onReset();
    },
    onReset() {
      this.form.amount = null;
      this.form.notes = null;
      this.form.userId = null;
    },
  },
}
</script>
