<template>
  <div class="home">
    <section
      class="main no-flex"
      style="margin-top: 13px;"
    >
      <!-- <Spinner
        v-if="!getIsLoading"
        style="padding-left:35%; padding-top:15%"
      />

      <UsersAttendance
        :user="user"
      /> -->
      <ExternalWorkingDays />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import ExternalWorkingDays from '../components/ExternalWorkingDaysListing.vue'
// import UsersAttendance from '@/components/properties/UsersAttedance.vue'


export default {
  name: 'Home',
  components: {
    ExternalWorkingDays,
    // UsersAttendance,
  },
  data() {
    return {
      user: '',
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
    handleUser(id) {
      this.user = id;
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  padding: 90px 15px 15px 110px;
}
@media screen and (max-width: 1200px){
  .main{
    padding: 90px 15px 15px 90px;
  }
}
@media screen and (max-width: 1041px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
